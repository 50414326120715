/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Amplify from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import { Person } from '../../models';
import awsExports from "../../aws-exports";


// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ViraNavbar from "examples/Navbars/ViraNavbar";

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import routes from "vira_routes";

Amplify.configure(awsExports);
DataStore.configure(awsExports);

function ContactUs() {
  const [checked, setChecked] = useState(true);
  const handleChecked = () => setChecked(!checked);

  const [first, setFirst] = useState("")
  const [last, setLast] = useState("")
  const [email, setEmail] = useState("")
  const [reason, setReason] = useState("")

  const pressButton = async(event) => {
    await DataStore.save(
      new Person({
      "first": first,
      "last": last,
      "email": email,
      "reason": reason
    })
  );
    
  };


  return (
    <>
      <ViraNavbar
        brand={"Vira"}
        routes={routes}
        transparent
        dark
      />
      <MKBox component="section" py={12}>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Contact Us
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput 
                    variant="standard" 
                    label="First Name" 
                    onChange={event => setFirst(event.target.value)}
                    fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput variant="standard" 
                  label="Last Name"
                  onChange={event => setLast(event.target.value)}
                  fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <MKInput variant="standard" 
                  type="email" 
                  label="Email Address" 
                  onChange={event => setEmail(event.target.value)}
                  fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <MKInput variant="standard" 
                  label="Your Message" 
                  multiline 
                  onChange={event => setReason(event.target.value)}
                  fullWidth rows={6} />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton 
                  variant="gradient" 
                  color="dark"
                  onClick={() => {pressButton(first, last, email, reason)}}
                  fullWidth>
                  Send Message
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
    </>
  );
}

export default ContactUs;
