/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import ViraNavbar from "examples/Navbars/ViraNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Home/sections/Information";
import Team from "pages/Home/sections/Team";
import Featuring from "pages/Home/sections/Featuring";
import Newsletter from "pages/Home/sections/Newsletter";

// Routes
import routes from "vira_routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/R.jpg";

function Home() {
  return (
    <>
      <ViraNavbar
        brand={"Vira"}
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Vira Technologies
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            Harnessing The World's Strategic OSINT
            </MKTypography>
            <Link to="#"
                  onClick={(e) => {
                    window.location.href="mailto:ishan@vira.bio";
                    e.preventDefault();
                  }}
            >
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Contact Us
            </MKButton>
            </Link>
            
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Home;
